import React, { useContext } from "react";
import { BsFillGridFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaRegCommentDots, FaRegHandshake, FaLightbulb, FaArrowDown } from "react-icons/fa";
import audios from "../audio";
import images from "../images";
import { ProgressContext } from "../contexts/ProgressContext";

const LessonsData = () => {
  const navigate = useNavigate();
  const { progress, updateProgress } = useContext(ProgressContext);

  const lessons = {
    englishLessons: [
      {
        level: 1,
        title: "Level 1.1",
        description: "Introduction to English",
        color: "#ff0000",
        progress: progress.EnglishL1,
        image: (
          <img
            src={images.mk7}
            alt="Level 1.1"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("English", "EnglishL1", 100, "/dashboard/english_lessons/level1_1");
        },
      },
      {
        level: 2,
        title: "Level 1.2",
        description: "Basic conversational skills in English",
        color: "#00ff00",
        progress: progress.EnglishL2,
        image: (
          <img
            src={images.mk3}
            alt="Level 1.2"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("English", "EnglishL2", 100, "/dashboard/english_lessons/level1_2");
        },
      },
      {
        level: 3,
        title: "Level 1.3",
        description: "Listening practice in English",
        color: "#0000ff",
        progress: progress.EnglishL3,
        image: (
          <img
            src={images.mk5}
            alt="Level 1.3"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("English", "EnglishL3", 0, "/dashboard/english_lessons/level1_3");
        },
      },
      {
        level: 4,
        title: "Lesson 1.4",
        description: "Practice Word Recognisation in English language",
        color: "#ffa500",
        progress: progress.EnglishL4,
        image: (
          <img
            src={images.mk6}
            alt="Lesson 1.4"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("English", "EnglishL4", 0, "/dashboard/english_lessons/level1_4");
        },
      },
    ],

    chineseLessons: [
      {
        level: 1,
        title: "Level 1.1",
        description: "Introduction to Chinese",
        color: "#ff0000",
        progress: progress.MandarinL1,
        image: (
          <img
            src={images.cap_boy}
            alt="Level 1.1"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Mandarin", "MandarinL1", 100, "/dashboard/chinese_lessons/level1_1");
        },
      },
      {
        level: 2,
        title: "Level 1.2",
        description: "Basic conversational skills in Chinese",
        color: "#00ff00",
        progress: progress.MandarinL2,
        image: (
          <img
            src={images.park_convo}
            alt="Level 1.2"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Mandarin", "MandarinL2", 100, "/dashboard/chinese_lessons/level1_2");
        },
      },
      {
        level: 3,
        title: "Level 1.3",
        description: "Listening practice in Chinese",
        color: "#0000ff",
        progress: progress.MandarinL3,
        image: (
          <img
            src={images.mk5}
            alt="Level 1.3"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Mandarin", "MandarinL3", 0, "/dashboard/chinese_lessons/level1_3");
        },
      },
      {
        level: 4,
        title: "Lesson 1.4",
        description: "Practice Word Recognisation in Chinese language",
        color: "#ffa500",
        progress: progress.MandarinL4,
        image: (
          <img
            src={images.mk1}
            alt="Lesson 1.4"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Mandarin", "MandarinL4", 0, "/dashboard/chinese_lessons/level1_4");
        },
      },
    ],

    spanishLessons: [
      {
        level: 1,
        title: "Level 1.1",
        description: "Introduction to Spanish",
        color: "#ff6347",
        progress: progress.SpanishL1,
        image: (
          <img
            src={images.mk4}
            alt="Spanish Level 1.1"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Spanish", "SpanishL1", 100, "/dashboard/spanish_lessons/level1_1");
        },
      },
      {
        level: 2,
        title: "Level 1.2",
        description: "Basic conversational skills in Spanish",
        color: "#98fb98",
        progress: progress.SpanishL2,
        image: (
          <img
            src={images.mk3}
            alt="Spanish Level 1.2"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Spanish", "SpanishL2", 100, "/dashboard/spanish_lessons/level1_2");
        },
      },
      {
        level: 3,
        title: "Level 1.3",
        description: "Listening Practice in Spanish",
        color: "#4682b4",
        progress: progress.SpanishL3,
        image: (
          <img
            src={images.mk5}
            alt="Spanish Level 1.3"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Spanish", "SpanishL3", 0, "/dashboard/spanish_lessons/level1_3");
        },
      },
      {
        level: 4,
        title: "Lesson 1.4",
        description: "Practice Word Recognisation in Spanish language",
        color: "#ffdab9",
        progress: progress.SpanishL4,
        image: (
          <img
            src={images.mk6}
            alt="Spanish Level 1.4"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("Spanish", "SpanishL4", 0, "/dashboard/spanish_lessons/level1_4");
        },
      },
    ],

    frenchLessons: [
      {
        level: 1,
        title: "Level 1.1",
        description: "Introduction to French",
        color: "#f08080",
        progress: progress.FrenchL1,
        image: (
          <img
            src={images.mk1}
            alt="French Level 1.1"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("French", "FrenchL1", 100, "/dashboard/french_lessons/level1_1");
        },
      },
      {
        level: 2,
        title: "Level 1.2",
        description: "Basic conversational skills in French",
        color: "#20b2aa",
        progress: progress.FrenchL2,
        image: (
          <img
            src={images.mk2}
            alt="French Level 1.2"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("French", "FrenchL2", 100, "/dashboard/french_lessons/level1_2");
        },
      },
      {
        level: 3,
        title: "Level 1.3",
        description: "Listening Practice in French",
        color: "#778899",
        progress: progress.FrenchL3,
        image: (
          <img
            src={images.mk8}
            alt="French Level 1.3"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("French", "FrenchL3", 0, "/dashboard/french_lessons/level1_3");
        },
      },
      {
        level: 4,
        title: "Lesson 1.4",
        description: "Practice Word Recognisation in French language",
        color: "#eedd82",
        progress: progress.FrenchL4,
        image: (
          <img
            src={images.mk7}
            alt="French Level 1.4"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              transition: "transform 0.5s",
            }}
          />
        ),
        onClick: () => {
          updateAndNavigate("French", "FrenchL4", 0, "/dashboard/french_lessons/level1_4");
        },
      },
    ],
  };

  const updateAndNavigate = (language, lesson, value, path) => {
    updateProgress(language, lesson, value);
    navigate(path);
  };

  // const updateAndOpen = (language, lesson, value, url) => {
  //   updateProgress(language, lesson, value);
  //   window.open(url, "_blank");
  // };

  return lessons;
};

const teamMembers = [
  // {
  //   role: "Advisor",
  //   image: images.david,
  //   slideDirection: "right",
  //   content: `
  //     With more than a dozen years of experience in healthcare and AI, I'm the co-founder and CEO of basys.ai, a healthtech startup. Our focus at basys.ai is utilizing generative AI to enhance prior authorization and utilization management for healthcare payers and providers. My responsibilities involve leading sales, strategy, and fundraising efforts, with a deep-seated passion for driving cost savings and better outcomes within the healthcare sector.

  //     I earned my MS in Health Data Science from Harvard University, where I was recognized as a Cheng Fellow, Roslyn and Lisle Payne Scholar, and recipient of the 40 under 40 award from the Boston Congress of Public Health. During my academic journey, I've contributed to three patents and authored several research papers on the intersection of AI and healthcare.

  //     My prior venture was as co-founder and CTO at kydots.ai, where I led both sales and engineering teams. We successfully delivered a SaaS product catering to enterprise clients in the financial management and human capital sectors. The venture resulted in two patents and a research paper detailing our proprietary platform before getting acquired.

  //     A firm believer in community and collective leadership, I'm enthusiastic about mentoring startups at TechStars, Harvard, MIT, MassChallenge, and XLerateHealth. I've also held the role of Co-Director for the Harvard Business Club, where I provided strategic support and fundraising guidance to Harvard-affiliated founders.

  //     slideDirection:"left",As an enthusiast of applied machine learning, I've been actively involved in both professional and academic initiatives within the Artificial Intelligence domain. One notable exam
  //     ple is co-instructing the "Collaborative Data Science in Medicine - HST.953" course at MIT. My contributions extend to top-tier machine learning conferences like NeurIPS and ACL and esteemed journals such as Lancet and Springer.

  //     Healthcare and technology are personal passions of mine, and I've been fortunate to share my insights on various public platforms. Invitations to speak at TEDx, Mayo Clinic, DuPont, Udacity, and other events have allowed me to delve into these crucial topics. Additionally, I've had the honor of having startups I have founded featured in Forbes and TechCrunch.
  //   `,
  // },
  {
    role: "Chief Executive Officer",
    image: images.nina,
    slideDirection: "left",
    content: `
      Born in China, adopted as a toddler, and raised in Maine, Nina began learning Mandarin at age 15 when she was awarded the National Security Language Initiative for Youth, a highly competitive cultural immersion and intensive language scholarship program of the US Department of State. She continued her Mandarin studies through college, earning the State Department’s Critical Language Scholarship in Suzhou, China in 2018. She graduated from Wheaton College in Norton, MA, with a BA in International Relations with a focus on Asia.
      
      Her love for teaching Chinese began with the realization that many American children don’t learn well with the strict and rote approach of many Chinese teachers. In response, she created an innovative learning approach so that children for whom Chinese is not a first language could actually have fun learning.
      
      In addition to teaching Mandarin to all ages, she has built her knowledge and teaching experience through substitute teaching, private gymnastics coaching, and tutoring in various subjects, including English, statistics, and AP Biology. She is passionate about entrepreneurship and believes that speaking both Mandarin and English is an asset in the international business realm.
      
      In her free time, Nina enjoys traveling to Asia, learning languages, and reading about business and startups. She has traveled to Qatar, Singapore, Hong Kong, Dubai, Bali, Barcelona, Croatia, India, and many major cities in China, such as Shenzhen, Xi’an, and Shanghai.
    `,
  },

  {
    role: "Advisor",
    image: images.david,
    slideDirection: "right",
    content: `
      Seasoned academic administrator with over 25 years of experience in leadership roles at selective, highly competitive primary and secondary private schools. 
       A firm believer in community and collective leadership, I'm enthusiastic about mentoring startups. Successful track record leading cross-functional teams and working collaboratively with stakeholders to keep organizations focused on the mission, actively engage in pursuing short and long-term goals, and position institutions not only for current sustainability but future advancement and growth.
    `,
  },

  {
    role: "Technical Co-Founder",
    image: images.amrit,
    slideDirection: "left",
    content: `
    I am a 4th-year undergraduate at IIT Kanpur in the Biological Sciences and Bioengineering department, with a passion for exploring new technologies in the Web3 domain, where I constantly seek to understand and contribute to the decentralized future. With a year of experience in teaching underprivileged kids as a volunteer, I enjoy making tough concepts of physics easier and engaging for lower secondary school students. In my free time, I indulge in playing chess, basketball, and badminton, while also being a keen follower of international cricket. Additionally, I find joy in reading and writing fictional texts, which fuel my creativity and imagination. ~Amrit`,
  },

  {
    role: "Technical Co-Founder",
    image: images.manthan,
    slideDirection: "right",
    content: `
    A passionate Full Stack Developer with a deep interest for crafting innovative and high-quality digital solutions. I'm passionate about Open-Source Contribution, React-Development, Full-Stack dev, WEB-3, etc. I am always eager to learn and work with new technologies. Collaboration and communication are at the core of my work philosophy. I thrive in diverse teams where I can leverage my interpersonal skills to effectively communicate ideas, contribute insights, and work towards a common objective. Beyond programming, I am a continuous learner, always eager to explore new technologies and expand my skill set. I believe in the power of lifelong learning to foster personal and professional growth. ~Manthan `,
  },

  {
    role: "Flutter Developer Intern",
    image: images.rushikesh,
    slideDirection: "left",
    content: `
    I am a final-year Computer Engineering student, passionate about building interactive apps that solve real-world problems. I have worked at Xchange, where I contributed to developing a book exchange platform. I love participating in hackathons, constantly exploring innovative solutions, and have secured 2nd place in the E-Cell competition and 3rd place in the Startup Spotlight competition in Haryana. With a strong interest in entrepreneurship and technology, I aim to create impactful Mobile Applications that enhance user experiences. ~Rushikesh`,
  },

  {
    role: "Tech Intern",
    image: images.harsh,
    slideDirection: "right",
    content: `
    Harsh Shukla a Computer Science student, Full Stack and Android Developer, combines technical expertise with creative problem-solving. 
    From contributing to Metamask with 40+ merged PRs to interning at GyaanCentral and DevStarter to build globally-used applications with a LCP of 2.6s for around 150MB of content, with a custom real-time database table editor, he's passionate about creating efficient, user-centric software solutions. 
    His work demonstrates his commitment to performance, scalability, and innovation in software development.
    Tech Stack: Next.js, PostgreSQL, Prisma, MERN Stack, TailwindCSS, Git, React Native, Expo and C/C++. ~Harsh `,
  },
  {
    role: "Social Media Intern",
    image: images.aayushi,
    slideDirection: "left",
    content: `
    Aayushi Jain, a Computer Science student at NSUT, excels in blockchain, UI/UX design, and competitive coding. Passionate about designing and coding for startups, she has interned at Adobe and rising companies like Staqu Technologies and API Swan. With over 17K followers on LinkedIn, she blends technical skills with leadership, earning top positions in hackathons and making impactful contributions in academics and extracurriculars.`,
  },
  {
    role: "HR Intern",
    image: images.aditi,
    slideDirection: "right",
    content: `
    Aditi Verma, an MBA student with a focus on HR Management, is passionate about pursuing a career in Human Resources. She leverages her skills in recruitment, employee engagement, and organizational behavior. During her internship at a non-profit organization, Aditi gained hands-on experience in coordinating interviews, maintaining records, and fostering a positive work environment. With a certification in Practical Digital Marketing, she also understands how online strategies can enhance HR functions.`,
  },
  {
    role: "Web Developer",
    image: images.anshdeep,
    slideDirection: "left",
    content: `I’m Anshdeep, a MERN stack developer skilled in Next.js, GSAP, and Three.js. I specialize in integrating OpenAI APIs to build dynamic, innovative web applications with seamless user experiences. ~Anshdeep`,
  },
  {
    role: "Tech Intern",
    image: images.kartikey,
    slideDirection: "right",
    content: `
    Kartikey Srivastava, currently a college student pursuing Computer Science. He began his journey in app development with Flutter, creating functional and visually appealing applications. With experience as an intern at Herculia, he also built OMNIA, a platform for the ACM community at his college. Driven by a hunger for knowledge and curiosity to learn, he loves working in teams, where he can inspire, learn, and grow together with others. ~Kartikey`,
  },
];

const testimonials = [
  {
    icon: <BsFillGridFill size={64} color="#ff0000" />,
    name: "Eren Jeager",
    person: "educator",
    description: `"This platform has transformed my teaching. The personalized learning paths and engaging gamification keep students motivated and on track. It's the perfect blend of fun and education!"`,
  },
  {
    icon: <BsFillGridFill size={64} color="#00ff00" />,
    name: "Erwin Smith",
    person: "parent",
    description: `"My child is more excited than ever to learn a new language. The interactive tools and real-time feedback make it easy for me to track progress. I’ve never seen my child so engaged!"`,
  },
  {
    icon: <BsFillGridFill size={64} color="#0000ff" />,
    name: "Alex Mercer",
    person: "student",
    description: `"Learning a new language feels like a game! The challenges and rewards make it super fun, and I’m more confident speaking and interacting with my friends now. This really is the best platform."`,
  },

  {
    icon: <BsFillGridFill size={64} color="#00ff00" />,
    name: "Garp",
    person: "parent",
    description: `" I can see my child’s progress in real-time, and the platform’s fun, interactive approach keeps them excited about learning."`,
  },
  {
    icon: <BsFillGridFill size={64} color="#0000ff" />,
    name: "Ace",
    person: "student",
    description: `"The way the lessons are structured helps me learn at my own pace, and I feel more confident speaking a new language."`,
  },
  {
    icon: <BsFillGridFill size={64} color="#ff0000" />,
    name: "Shanks",
    person: "educator",
    description: `"The interactive elements and real-time feedback help me ensure every student is on track, making my classroom more effective."`,
  },
];

const measures = [
  {
    icon: <FaRegCommentDots size={64} color="#31d6ff" />,
    person: "Engagement",
    description: "She really enjoyed learning from you.",
  },
  {
    icon: <FaRegHandshake size={64} color="#00ff00" />,
    person: "Retention",
    description: "He was so excited for you to come.",
  },
  {
    icon: <FaLightbulb size={64} color="#0000ff" />,
    person: "Improvements",
    description: "She really interested to adapt language.",
  },
  {
    icon: <FaArrowDown size={64} color="#ff0000" />,
    person: "Regression",
    description: "She really interested to adapt language.",
  },
];

const programs = [
  {
    id: 1,
    img: images.one,
    desc: "Click the Start Free Trial Button to begin.",
  },
  {
    id: 2,
    img: images.two,
    desc: "Our team will arrange a demo call within 24 hours.",
  },
  {
    id: 3,
    img: images.three,
    desc: "Our Engineering Team will guide you through tool setup.",
  },

  {
    id: 4,
    img: images.four,
    desc: "Commence your free trial: Your journey starts here.",
  },
];

const demoVdos = [
  {
    id: 1,
    name: "Student Dashboard",
    vdo: images.studentDemo,
  },
  {
    id: 2,
    name: "Parent Dashboard",
    vdo: images.parentDemo,
  },
  {
    id: 3,
    name: "Teacher Dashboard",
    vdo: images.teacherDemo,
  },
];

const storiesVdos = [
  {
    id: 1,
    name: "Story - 1",
    vdo: images.storyVdo1,
  },
  {
    id: 2,
    name: "Story - 2",
    vdo: images.storyVdo2,
  },
  {
    id: 3,
    name: "Story - 3",
    vdo: images.storyVdo3,
  },
  {
    id: 4,
    name: "Story - 4",
    vdo: images.storyVdo4,
  },
  {
    id: 5,
    name: "Story - 5",
    vdo: images.storyVdo5,
  },
];

const faqs = {
  lmsFaq: [
    {
      question: "What is the Learning Management System (LMS) offered by Happy Plum?",
      answer:
        "Happy Plum's LMS is an innovative platform that provides personalized learning paths, immersive cultural experiences, and interactive gamification to support K-12 students in their language learning journey. It is designed to help parents and teachers gain deeper insights into student progress and improve learning outcomes.",
    },
    {
      question: "What languages are supported on Happy Plum's LMS?",
      answer:
        "Currently, Happy Plum supports multiple languages, including English, Chineese, French, Spanish. We are continually adding new languages to enhance our offerings and better serve diverse learners.",
    },
    {
      question: "Can parents be involved in their child's language learning journey?",
      answer:
        "Absolutely! Happy Plum provides a dashboard for parents to monitor their child's progress, achievements, and areas that need more attention. This allows parents to stay engaged in their child's education.",
    },
    {
      question: "How secure is student data on Happy Plum's LMS?",
      answer:
        "We prioritize data security and ensure that all student information is encrypted and stored in compliance with privacy laws. Our LMS is designed with multiple layers of security to protect sensitive data.",
    },
    {
      question: "How does Happy Plum integrate gamification into learning?",
      answer:
        "Our LMS uses interactive games, challenges, and rewards to engage students. These gamified elements make learning fun and encourage students to continue practicing their language skills outside of traditional lessons.",
    },
  ],
  aboutusFaq: [
    {
      question: "What is Happy Plum's mission?",
      answer:
        "Happy Plum aims to revolutionize education by providing innovative learning solutions for parents and teachers, focusing on personalized, culturally immersive, and gamified experiences for K-12 language learners.",
    },
    {
      question: "What sets Happy Plum apart from other language learning platforms?",
      answer:
        "Happy Plum combines personalized learning paths, cultural immersion, and interactive gamification to create a unique, holistic approach to language education.",
    },
    {
      question: "How can I get in touch with the Happy Plum team?",
      answer:
        "You can contact us through our website by visiting the Contact-Us page, where you'll find details on how to reach our support and customer service teams.",
    },
    {
      question: "Is Happy Plum continuously updating its content and features?",
      answer:
        "Yes! We are committed to regularly updating our content and features to stay aligned with educational standards, new technologies, and user feedback to provide the best experience for learners and educators.",
    },
    {
      question: "Who is behind Happy Plum?",
      answer:
        "Happy Plum was developed by a team of passionate educators, developers, and technologists committed to transforming the future of language learning. Our team brings together years of experience in education, technology, and linguistics.",
    },
  ],
  parentsFaq: [
    {
      question: "How can I monitor my child’s progress?",
      answer:
        "The platform provides parents with regular progress reports, insights into their child's learning journey, and recommendations for supporting their development at home.",
    },
    {
      question: "Can my child use the platform independently?",
      answer:
        "Yes, the platform is designed to be user-friendly for young children, with intuitive navigation and clear instructions that allow them to use it independently.",
    },
    {
      question: "How does the platform cater to my child’s specific learning needs?",
      answer:
        "The AI adapts to your child's learning pace and style, offering customized activities and lessons that target their strengths and areas for improvement.",
    },
    {
      question: "How much time should my child spend on the platform daily?",
      answer:
        "It is recommended to use the platform for 15-30 minutes daily, depending on your child’s engagement and attention span.",
    },
    {
      question: "Is there a trial period or demo available?",
      answer:
        "Yes, a free trial period is available for parents to explore the platform and see how it benefits their child before committing to a subscription.",
    },
  ],
  teachersFaq: [
    {
      question: "How can the platform be integrated into our existing curriculum?",
      answer:
        "The platform offers flexible integration options, allowing teachers to incorporate it into daily lessons, use it for supplementary activities, or assign it for homework.",
    },
    {
      question: "What are the educational benefits of using an AI-language learning platform?",
      answer:
        "The platform supports differentiated instruction, personalized learning, and helps improve language proficiency through adaptive technology tailored to each student’s needs.",
    },
    {
      question: "How does the platform support teachers in tracking student progress?",
      answer:
        "Teachers receive detailed analytics and reports on student performance, enabling them to identify areas where students need additional support or enrichment.",
    },
    {
      question: "How is the content updated, and who develops it?",
      answer:
        "The content is regularly updated by a team of educational experts and AI specialists to ensure it remains relevant, engaging, and aligned with educational standards.",
    },
    {
      question: "What is the cost of implementing this platform in our school?",
      answer:
        "Pricing varies based on the number of students and the specific needs of the school. We offer customized packages to fit different budgets.",
    },
  ],
};

const englishQuestions = {
  easy: [
    {
      questionNumber: "question1",
      audio: audios.english_q1,
      options: [images.whatsurname, images.peopleinfamily],
      correctOption: 0,
      text: { english: ["What's your name?", "How many people are in your family?"] },
    },
    {
      questionNumber: "question2",
      audio: audios.english_q2,
      options: [images.howru, images.oldyou],
      correctOption: 1,
      text: { english: ["Hello, how are you?", "How old are you?"] },
    },
  ],
  medium: [
    {
      questionNumber: "question3",
      audio: audios.english_q3,
      options: [images.whoinfam, images.howru],
      correctOption: 1,
      text: { english: ["Who is in your family?", "Hello, how are you?"] },
    },
  ],
  hard: [
    {
      questionNumber: "question4",
      audio: audios.english_q4,
      options: [images.peopleinfamily, images.whatsurname],
      correctOption: 0,
      text: { english: ["How many people are in your family?", "What's your name?"] },
    },
    {
      questionNumber: "question5",
      audio: audios.english_q5,
      options: [images.oldyou, images.whoinfam],
      correctOption: 1,
      text: { english: ["How old are you?", "Who is in your family?"] },
    },
  ],
};

const chineseQuestions = {
  easy: [
    {
      questionNumber: "question1",
      audio: audios.chinese_q1,
      options: [images.whatsurname, images.peopleinfamily],
      correctOption: 0,
      text: { english: ["What's your name?", "How many people are in your family?"] },
    },
    {
      questionNumber: "question2",
      audio: audios.chinese_q2,
      options: [images.howru, images.oldyou],
      correctOption: 1,
      text: { english: ["Hello, how are you?", "How old are you?"] },
    },
  ],
  medium: [
    {
      questionNumber: "question3",
      audio: audios.chinese_q3,
      options: [images.whoinfam, images.howru],
      correctOption: 1,
      text: { english: ["Who is in your family?", "Hello, how are you?"] },
    },
  ],
  hard: [
    {
      questionNumber: "question4",
      audio: audios.chinese_q4,
      options: [images.peopleinfamily, images.whatsurname],
      correctOption: 0,
      text: { english: ["How many people are in your family?", "What's your name?"] },
    },
    {
      questionNumber: "question5",
      audio: audios.chinese_q5,
      options: [images.oldyou, images.whoinfam],
      correctOption: 1,
      text: { english: ["How old are you?", "Who is in your family?"] },
    },
  ],
};

const spanishQuestions = {
  easy: [
    {
      questionNumber: "question1",
      audio: audios.spanish_q1,
      options: [images.whatsurname, images.peopleinfamily],
      correctOption: 0,
      text: { english: ["What's your name?", "How many people are in your family?"] },
    },
    {
      questionNumber: "question2",
      audio: audios.spanish_q2,
      options: [images.howru, images.oldyou],
      correctOption: 1,
      text: { english: ["Hello, how are you?", "How old are you?"] },
    },
  ],
  medium: [
    {
      questionNumber: "question3",
      audio: audios.spanish_q3,
      options: [images.whoinfam, images.howru],
      correctOption: 1,
      text: { english: ["Who is in your family?", "Hello, how are you?"] },
    },
  ],
  hard: [
    {
      questionNumber: "question4",
      audio: audios.spanish_q4,
      options: [images.peopleinfamily, images.whatsurname],
      correctOption: 0,
      text: { english: ["How many people are in your family?", "What's your name?"] },
    },
    {
      questionNumber: "question5",
      audio: audios.spanish_q5,
      options: [images.oldyou, images.whoinfam],
      correctOption: 1,
      text: { english: ["How old are you?", "Who is in your family?"] },
    },
  ],
};

const frenchQuestions = {
  easy: [
    {
      questionNumber: "question1",
      audio: audios.french_q1,
      options: [images.whatsurname, images.peopleinfamily],
      correctOption: 0,
      text: { english: ["What's your name?", "How many people are in your family?"] },
    },
    {
      questionNumber: "question2",
      audio: audios.french_q2,
      options: [images.howru, images.oldyou],
      correctOption: 1,
      text: { english: ["Hello, how are you?", "How old are you?"] },
    },
  ],
  medium: [
    {
      questionNumber: "question3",
      audio: audios.french_q3,
      options: [images.whoinfam, images.howru],
      correctOption: 1,
      text: { english: ["Who is in your family?", "Hello, how are you?"] },
    },
  ],
  hard: [
    {
      questionNumber: "question4",
      audio: audios.french_q4,
      options: [images.peopleinfamily, images.whatsurname],
      correctOption: 0,
      text: { english: ["How many people are in your family?", "What's your name?"] },
    },
    {
      questionNumber: "question5",
      audio: audios.french_q5,
      options: [images.oldyou, images.whoinfam],
      correctOption: 1,
      text: { english: ["How old are you?", "Who is in your family?"] },
    },
  ],
};

const testData = {
  Mandarin: {
    New: [
      {
        question: 'What is the word for "Hello" in Mandarin?',
        options: ["你好", "谢谢", "再见"],
        correctAnswer: "你好",
      },
      {
        question: 'What is the word for "Thank you" in Mandarin?',
        options: ["谢谢", "你好", "再见"],
        correctAnswer: "谢谢",
      },
      {
        question: 'What is the word for "Goodbye" in Mandarin?',
        options: ["再见", "你好", "谢谢"],
        correctAnswer: "再见",
      },
    ],
    Conversational: [
      {
        question: 'What does "谢谢" mean in Mandarin?',
        options: ["Thank you", "Goodbye", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good morning" in Mandarin?',
        options: ["早上好", "晚上好", "再见"],
        correctAnswer: "早上好",
      },
      {
        question: 'What is "Yes" in Mandarin?',
        options: ["是", "否", "谢谢"],
        correctAnswer: "是",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "I don’t understand" in Mandarin?',
        options: ["我不明白", "我很好", "你好吗"],
        correctAnswer: "我不明白",
      },
      {
        question: 'Translate "Where is the bathroom?" to Mandarin.',
        options: ["洗手间在哪里？", "你好吗？", "我很好"],
        correctAnswer: "洗手间在哪里？",
      },
      {
        question: 'How do you say "I would like to buy this" in Mandarin?',
        options: ["我想买这个", "谢谢", "再见"],
        correctAnswer: "我想买这个",
      },
    ],
  },
  French: {
    New: [
      {
        question: 'What is the word for "Hello" in French?',
        options: ["Bonjour", "Merci", "Au revoir"],
        correctAnswer: "Bonjour",
      },
      {
        question: 'What is the word for "Thank you" in French?',
        options: ["Merci", "Bonjour", "Salut"],
        correctAnswer: "Merci",
      },
      {
        question: 'What is the word for "Goodbye" in French?',
        options: ["Au revoir", "Salut", "Merci"],
        correctAnswer: "Au revoir",
      },
    ],
    Conversational: [
      {
        question: 'Translate "Good morning" to French.',
        options: ["Bonjour", "Bonne nuit", "Salut"],
        correctAnswer: "Bonjour",
      },
      {
        question: 'What does "Merci" mean in French?',
        options: ["Thank you", "Please", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good evening" in French?',
        options: ["Bonsoir", "Bonne nuit", "Salut"],
        correctAnswer: "Bonsoir",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "Where is the bathroom?" in French?',
        options: ["Où est la salle de bain?", "Comment ça va?", "Je suis fatigué"],
        correctAnswer: "Où est la salle de bain?",
      },
      {
        question: 'Translate "I would like a coffee" to French.',
        options: ["Je voudrais un café", "Merci beaucoup", "Bonjour"],
        correctAnswer: "Je voudrais un café",
      },
      {
        question: 'How do you say "I’m sorry" in French?',
        options: ["Je suis désolé", "Merci", "Bonjour"],
        correctAnswer: "Je suis désolé",
      },
    ],
  },
  Spanish: {
    New: [
      {
        question: 'What is the word for "Hello" in Spanish?',
        options: ["Hola", "Gracias", "Adiós"],
        correctAnswer: "Hola",
      },
      {
        question: 'What is the word for "Thank you" in Spanish?',
        options: ["Gracias", "Hola", "Adiós"],
        correctAnswer: "Gracias",
      },
      {
        question: 'What is the word for "Goodbye" in Spanish?',
        options: ["Adiós", "Hola", "Gracias"],
        correctAnswer: "Adiós",
      },
    ],
    Conversational: [
      {
        question: 'Translate "Good morning" to Spanish.',
        options: ["Buenos días", "Buenas noches", "Hola"],
        correctAnswer: "Buenos días",
      },
      {
        question: 'What does "Gracias" mean in Spanish?',
        options: ["Thank you", "Please", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good evening" in Spanish?',
        options: ["Buenas tardes", "Buenas noches", "Hola"],
        correctAnswer: "Buenas tardes",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "Where is the bathroom?" in Spanish?',
        options: ["¿Dónde está el baño?", "¿Cómo estás?", "Estoy cansado"],
        correctAnswer: "¿Dónde está el baño?",
      },
      {
        question: 'Translate "I would like a coffee" to Spanish.',
        options: ["Quisiera un café", "Gracias", "Buenos días"],
        correctAnswer: "Quisiera un café",
      },
      {
        question: 'How do you say "I’m sorry" in Spanish?',
        options: ["Lo siento", "Gracias", "Hola"],
        correctAnswer: "Lo siento",
      },
    ],
  },
  Latin: {
    New: [
      {
        question: 'What is the word for "Hello" in Latin?',
        options: ["Salve", "Gratias", "Vale"],
        correctAnswer: "Salve",
      },
      {
        question: 'What is the word for "Thank you" in Latin?',
        options: ["Gratias", "Salve", "Vale"],
        correctAnswer: "Gratias",
      },
      {
        question: 'What is the word for "Goodbye" in Latin?',
        options: ["Vale", "Salve", "Gratias"],
        correctAnswer: "Vale",
      },
    ],
    Conversational: [
      {
        question: 'Translate "Good morning" to Latin.',
        options: ["Bonum mane", "Bona nox", "Salve"],
        correctAnswer: "Bonum mane",
      },
      {
        question: 'What does "Gratias" mean in Latin?',
        options: ["Thank you", "Please", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good evening" in Latin?',
        options: ["Bona vesper", "Bona nox", "Salve"],
        correctAnswer: "Bona vesper",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "Where is the bathroom?" in Latin?',
        options: ["Ubi est latrina?", "Quid agis?", "Ego sum fessus"],
        correctAnswer: "Ubi est latrina?",
      },
      {
        question: 'Translate "I would like a coffee" to Latin.',
        options: ["Velim caffe", "Gratias", "Bonum mane"],
        correctAnswer: "Velim caffe",
      },
      {
        question: 'How do you say "I’m sorry" in Latin?',
        options: ["Mea culpa", "Gratias", "Salve"],
        correctAnswer: "Mea culpa",
      },
    ],
  },
  Arabic: {
    New: [
      {
        question: 'What is the word for "Hello" in Arabic?',
        options: ["مرحبا", "شكرا", "وداعا"],
        correctAnswer: "مرحبا",
      },
      {
        question: 'What is the word for "Thank you" in Arabic?',
        options: ["شكرا", "مرحبا", "وداعا"],
        correctAnswer: "شكرا",
      },
      {
        question: 'What is the word for "Goodbye" in Arabic?',
        options: ["وداعا", "مرحبا", "شكرا"],
        correctAnswer: "وداعا",
      },
    ],
    Conversational: [
      {
        question: 'Translate "Good morning" to Arabic.',
        options: ["صباح الخير", "مساء الخير", "مرحبا"],
        correctAnswer: "صباح الخير",
      },
      {
        question: 'What does "شكرا" mean in Arabic?',
        options: ["Thank you", "Please", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good evening" in Arabic?',
        options: ["مساء الخير", "صباح الخير", "مرحبا"],
        correctAnswer: "مساء الخير",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "Where is the bathroom?" in Arabic?',
        options: ["أين الحمام؟", "كيف حالك؟", "أنا متعب"],
        correctAnswer: "أين الحمام؟",
      },
      {
        question: 'Translate "I would like a coffee" to Arabic.',
        options: ["أود قهوة", "شكرا", "صباح الخير"],
        correctAnswer: "أود قهوة",
      },
      {
        question: 'How do you say "I’m sorry" in Arabic?',
        options: ["أنا آسف", "شكرا", "مرحبا"],
        correctAnswer: "أنا آسف",
      },
    ],
  },
  Hindi: {
    New: [
      {
        question: 'What is the word for "Hello" in Hindi?',
        options: ["नमस्ते", "धन्यवाद", "अलविदा"],
        correctAnswer: "नमस्ते",
      },
      {
        question: 'What is the word for "Thank you" in Hindi?',
        options: ["धन्यवाद", "नमस्ते", "अलविदा"],
        correctAnswer: "धन्यवाद",
      },
      {
        question: 'What is the word for "Goodbye" in Hindi?',
        options: ["अलविदा", "नमस्ते", "धन्यवाद"],
        correctAnswer: "अलविदा",
      },
    ],
    Conversational: [
      {
        question: 'Translate "Good morning" to Hindi.',
        options: ["सुप्रभात", "शुभ रात्रि", "नमस्ते"],
        correctAnswer: "सुप्रभात",
      },
      {
        question: 'What does "धन्यवाद" mean in Hindi?',
        options: ["Thank you", "Please", "Hello"],
        correctAnswer: "Thank you",
      },
      {
        question: 'How do you say "Good evening" in Hindi?',
        options: ["शुभ संध्या", "सुप्रभात", "नमस्ते"],
        correctAnswer: "शुभ संध्या",
      },
    ],
    Intermediate: [
      {
        question: 'How do you say "Where is the bathroom?" in Hindi?',
        options: ["बाथरूम कहाँ है?", "आप कैसे हैं?", "मैं थक गया हूँ"],
        correctAnswer: "बाथरूम कहाँ है?",
      },
      {
        question: 'Translate "I would like a coffee" to Hindi.',
        options: ["मुझे एक कॉफी चाहिए", "धन्यवाद", "सुप्रभात"],
        correctAnswer: "मुझे एक कॉफी चाहिए",
      },
      {
        question: 'How do you say "I’m sorry" in Hindi?',
        options: ["मुझे खेद है", "धन्यवाद", "नमस्ते"],
        correctAnswer: "मुझे खेद है",
      },
    ],
  },
};

const culturesData = {
  mandarin: {
    title: "Mandarin Culture",
    headerColor: "red",
    headerBG: "#ffc8e2d7",
    videoURL: images.mandarinCultureVdo,
    festivals: [
      { name: "Chinese New Year", img: images.chinese_new_year },
      { name: "Mid-Autumn Festival", img: images.mid_autumn },
      { name: "Dragon Boat Festival", img: images.dragon_boat },
    ],
    emperors: [
      { name: "Qin Shi Huang", img: images.qin_shi_huang },
      { name: "Emperor Wu of Han", img: images.emperor_wu },
      { name: "Kangxi Emperor", img: images.kangxi },
    ],
    living: {
      description: "Life in China is a mix of modern urban living and rich traditional culture...",
      img: images.china_living,
    },
    daily: {
      description: "Daily life includes bustling markets, family gatherings...",
      img: images.china_daily,
    },
    img: images.china_culture,
  },
  french: {
    title: "French Culture",
    headerColor: "green",
    headerBG: "#96ffbbd7",
    videoURL: images.frenchCultureVdo,
    festivals: [
      { name: "Bastille Day", img: images.bastille_day },
      { name: "Fête de la Musique", img: images.fete_musique },
      { name: "Cannes Film Festival", img: images.cannes },
    ],
    emperors: [
      { name: "Louis XIV", img: images.louis_xiv },
      { name: "Napoleon Bonaparte", img: images.napoleon },
      { name: "Charlemagne", img: images.charlemagne },
    ],
    living: {
      description: "Life in France is known for its focus on art, culture, and cuisine...",
      img: images.france_living,
    },
    daily: {
      description: "Daily life includes enjoying fresh pastries, walking in beautiful parks...",
      img: images.france_daily,
    },
    img: images.french_culture,
  },
  spanish: {
    title: "Spanish Culture",
    headerColor: "blue",
    headerBG: "#b6e4ffd7",
    videoURL: images.spanishCultureVdo,
    festivals: [
      { name: "La Tomatina", img: images.la_tomatina },
      { name: "Running of the Bulls", img: images.bulls_festival },
      { name: "Semana Santa", img: images.semana_santa },
    ],
    emperors: [
      { name: "Charles V", img: images.charles_v },
      { name: "Isabella I of Castile", img: images.isabella },
      { name: "Philip II", img: images.philip_ii },
    ],
    living: {
      description:
        "Life in Spain is vibrant, with a strong focus on community, food, and festivals...",
      img: images.spain_living,
    },
    daily: {
      description: "Daily life includes enjoying tapas with friends...",
      img: images.spain_daily,
    },
    img: images.spanish_culture,
  },
};

export {
  LessonsData,
  teamMembers,
  testimonials,
  measures,
  programs,
  demoVdos,
  storiesVdos,
  faqs,
  englishQuestions,
  chineseQuestions,
  spanishQuestions,
  frenchQuestions,
  testData,
  culturesData,
};
